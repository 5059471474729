// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/activestorage").start()
require("./channels")

import { Turbo } from "@hotwired/turbo-rails"
import TurboPower from "turbo_power"
TurboPower.initialize(Turbo.StreamActions)

import "bootstrap"
import "chartkick"
import "chart.js"

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { faTwitter, fab } from "@fortawesome/free-brands-svg-icons"
library.add(fas, far, faTwitter, fab)
import Inputmask from "inputmask"
import bsCustomFileInput from "bs-custom-file-input"
import flatpickr from "flatpickr"
import "./packs/admin/sidebar_toggle.js"
import "./packs/admin/sidebar_group_list.js"

import "./components/NavbarUsername.js"

import "trix"
import "@rails/actiontext"

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Turbo.scroll = {}

addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event
  const { id, file } = detail
  target.parentElement.parentElement.insertAdjacentHTML(
    "afterend",
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
    </div>
  `
  )
})

addEventListener("direct-upload:start", (event) => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`
  )
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", (event) => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
  element.insertAdjacentHTML("beforeend", error)
})

addEventListener("direct-upload:end", (event) => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

function applyInputMask() {
  Inputmask().mask(document.querySelectorAll("input.maskable-input"))
}

document.addEventListener("turbo:load", () => {
  const inputs = Array.from(document.querySelectorAll("input[type=file]"))
  inputs.forEach((input) => {
    input.addEventListener("change", (event) => {
      event.target.form.querySelector(".file-upload").click()
    })
  })

  const elements = document.querySelectorAll("[data-turbo-scroll]")

  elements.forEach(function (element) {
    element.addEventListener("click", () => {
      Turbo.scroll["top"] = document.scrollingElement.scrollTop
    })

    element.addEventListener("submit", () => {
      Turbo.scroll["top"] = document.scrollingElement.scrollTop
    })
  })

  if (Turbo.scroll["top"]) {
    document.scrollingElement.scrollTo(0, Turbo.scroll["top"])
  }

  Turbo.scroll = {}

  applyInputMask()
  bsCustomFileInput.init()
})

const beneficiaryBankDetails = (companyId) => {
  const bankDetailsNames = [
    // Modulr
    "client_beneficiary_account_number",
    "client_beneficiary_sort_code",
    // Airwallex
    "client_beneficiary_iban_account_number",
    "client_beneficiary_bank_code",
    "client_beneficiary_bank_account_number",
  ]

  const bankDetails = Object.fromEntries(
    bankDetailsNames
      .map((bankDetail) => $(`#${bankDetail}`)[0])
      .filter((bankDetailInput) => bankDetailInput !== undefined)
      .map((bankDetailInput) => [
        bankDetailInput.name.match(/\[(.*)\]/).pop(),
        bankDetailInput.value,
      ])
  )

  $.ajax({
    url: `/admin/companies/${companyId}/client/beneficiaries/uniq_bank_details`,
    type: "POST",
    data: bankDetails,
    success: (data) => {
      if (data.uniq === true) {
        $("#submit-create-beneficiary").trigger("click")
      } else {
        $("#admin-duplicate-beneficiary-modal-button").trigger("click")
      }
    },
  })
}

window.beneficiaryBankDetails = beneficiaryBankDetails
